
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaDownloadBtn from '@/components/downloadQRCode.vue';

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { CommonApi } from "@/common/api/common";
import { WithholdChessAPI } from "@/common/api/merchantManage/withholdChess";

import gallery from "@/components/gallery.vue";

import { shareConfirmOptions, getCommonList } from "@/common/config";

export const pageApiList = getPageAPIs(WithholdChessAPI);


// 喇叭类型 0无 1绿米 2闪优 3智控宝
const deviceType2Fields: any = {
  'lvmi': 1,
  'sanyou': 2,
  'zhikong': 3
}

const deviceField2Type: string[] = ['', 'lvmi', 'sanyou', 'zhikong']
const deviceType2ch: string[] = ['', '绿米', '闪优', '智控宝']

const defaultForm: any = {
  device_type: 1,
  horn_type: 1,
  // name: '',
  // subject_id: '',
  // resource_id: '',
  // serial_number: '',
  // lock_data: '',
  // pool_table_category_id: '',
  // is_status: 1,
  // cover_ids: '',
  is_status: 1,
  lock_enable_remote: 1,
}


const maxHornTime: number = 999

@Component({
  components: {
    gallery,
    PandaDownloadBtn,
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class Withhold extends Vue {
   /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '包厢名称',
      field: 'name'
    },
    {
      label: '开关ID',
      field: 'subject_id'
    },
    {
      label: '按钮ID',
      field: 'resource_id'
    },
    {
      label: '云喇叭开关ID',
      field: 'horn_subject_id'
    },
    {
      label: '云喇叭按钮ID',
      field: 'horn_resource_id'
    },
    {
      label: '通电时长',
      field: 'horn_time'
    },
    {
      label: '包厢分类',
      field: 'pool_table_category_name'
    },
    {
      label: '小程序二维码下载',
      field: 'qrcode_link'
    },
    {
      label: '排序',
      field: 'serial_number'
    },
    {
      label: '状态',
      field: 'table-is-status'
    },
  ];
  /** end 移动端相关属性 方法 */

  private merchantApi: any;
  private commonApi = new CommonApi();

  private deepLoopUtil: any;

  private hornDeviceTypeList: any[] = getCommonList(deviceType2ch, 1)

  private get showDeviceGateTypeList() {
    const hornDeviceTypeList = this.hornDeviceTypeList

    const fibiddenDeviceLabels: string[] = ['闪优']

    return hornDeviceTypeList.filter(({ label }: any) => {
      return !fibiddenDeviceLabels.includes(label)
    })
  }

  private get showDeviceHornTypeList() {
    const hornDeviceTypeList = this.hornDeviceTypeList

    const fibiddenDeviceLabels: string[] = ['智控宝']

    return hornDeviceTypeList.filter(({ label }: any) => {
      return !fibiddenDeviceLabels.includes(label)
    })
  }

  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();

    this.merchantApi = new WithholdChessAPI();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];

  private handlePictureCardPreview(file: any, mode: string) {
    const cover = this.cover;

    const images = Array.isArray(cover) ? cover : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
    ];

    this.previewImages = urls;
  }

  // 预览列表媒体文件
  private handlePreviewMedia(row: any, mode: string) {
    const medias: any[] = [];
    if (mode == "image") {
      medias.push(
        ...(row.cover_urls || []).map((pic: any) => {
          return {
            title: "",
            type: "image/jpeg",
            href: pic.url,
            thumbnail: pic.url,
          };
        })
      );
    } else if (mode == "video") {
      // medias.push(
      //   ...(row.videoList || []).map((video: any) => {
      //     return {
      //       title: "",
      //       type: "video/mp4",
      //       href: video.url,
      //       poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
      //     };
      //   })
      // );
    }

    this.previewImages = medias;
  }

  // 预览编辑封面的媒体
  private previewEditFormCovres(file: any, mode: string) {
    const medias: any[] = [];

    const cover = this.cover || [];
    if (mode == "image") {
      medias.push(
        ...(cover || []).map((pic: any) => {
          return {
            title: "",
            type: "image/jpeg",
            href: pic.url,
            thumbnail: pic.url,
          };
        })
      );
    } else if (mode == "video") {
      // medias.push(
      //   ...(row.videoList || []).map((video: any) => {
      //     return {
      //       title: "",
      //       type: "video/mp4",
      //       href: video.url,
      //       poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
      //     };
      //   })
      // );
    }

    this.previewImages = medias;
  }

  private show: boolean = false;
  // private dialogVisible: boolean = false;
  // private dialogImageUrl: string = "";
  private hideUpload: boolean = false;
  private tableData: any[] = [];
  private cover: any = [];
  private form: any = {};
  private activated(): void {
    this.getTable();
    this.commonDeviceList();
    this.poolTableCategory();
  }

  private commonDList: any[] = []

  private commonDeviceList() {
    const form = this.form

    const deviceType = deviceField2Type[form.device_type] || ''


    console.log(deviceType, 'deviceType device')

    return new Promise((resolve, reject) => {
      switch (deviceType) {
        case 'lvmi':
          this.merchantApi.device(
            {
              aqara_id: this.$route.query.aqara_id,
            },
            (res: any) => {
              if (res.data.list) {
                this.commonDList = res.data.list;
              } else {
                this.commonDList = [];
              }

              resolve(res)
            },
            (err: any) => {
              this.commonDList = [];
              reject(err)
            }
          );
          break;
        case 'sanyou':
          this.merchantApi.sanYouDevice(
            {
              shop_id: this.$route.query.id,
            },
            (res: any) => {
              console.log(res.data, 'res.data')
              if (res.data) {
                this.commonDList = res.data.map((item: any) => {
                  const {
                    mcuid,
                    mcuname
                  } = item || {}
                  const newItem = {
                    ...item,
                    deviceName: mcuname,
                    did: mcuid
                  }

                  return newItem
                });
              } else {
                this.commonDList = [];
              }

              resolve(res)
            },
            (err: any) => {
              this.commonDList = [];
              reject(err)
            }
          );
          break;
        case 'zhikong':
          this.merchantApi.zkbDevice(
            {
              zkb_user_id: this.$route.query.zkb_user_id,
            },
            (res: any) => {
              console.log(res.data, 'res.data')
              const {
                list = []
              } = res.data || {}

              this.commonDList = list.map((item: any) => {
                const {
                  product_id,
                  name
                } = item || {}
                const newItem = {
                  ...item,
                  deviceName: name,
                  did: product_id
                }

                return newItem
              });

              resolve(res)
            },
            (err: any) => {
              this.commonDList = [];
              reject(err)
            }
          );
          break;
        default:
          return resolve(null)
      }
    })
  }

  private handleChangeDeviceType(val: any) {
    Object.assign(this.form, {
      subject_id: '',
      resource_id: ''
    })

    this.curDeviceGate = {}
    this.commonDeviceList()
  }

  private get isChooseDeviceBtn() {
    const form = this.form

    const {
      device_type
    } = form || {}

    // 绿米、智控宝设备
    const allowChooseDeviceBtnTypes: number[] = [1, 3]

    return allowChooseDeviceBtnTypes.includes(device_type)
  }

  private dList: any = [];

  private deviceType: string = 'lvmi' // lvmi 绿米

  private device(): Promise<any> {
    const deviceType = this.deviceType

    console.log(deviceType, 'deviceType device')

    return new Promise((resolve, reject) => {
      switch (deviceType) {
        case 'lvmi':
          this.merchantApi.device(
            {
              aqara_id: this.$route.query.aqara_id,
            },
            (res: any) => {
              if (res.data.list) {
                this.dList = res.data.list;
              } else {
                this.dList = [];
              }

              resolve(res)
            },
            (err: any) => {
              this.dList = [];
              reject(err)
            }
          );
          break;
        case 'sanyou':
          this.merchantApi.sanYouDevice(
            {
              shop_id: this.$route.query.id,
            },
            (res: any) => {
              console.log(res.data, 'res.data')
              if (res.data) {
                this.dList = res.data.map((item: any) => {
                  const {
                    mcuid,
                    mcuname
                  } = item || {}
                  const newItem = {
                    ...item,
                    deviceName: mcuname,
                    did: mcuid
                  }

                  return newItem
                });
              } else {
                this.dList = [];
              }

              resolve(res)
            },
            (err: any) => {
              this.dList = [];
              reject(err)
            }
          );
          break;
        default:
          return resolve(null)
      }
    })
    

    
  }
  private rList: any = [];

  private curDeviceGate: any = {}

  private resource(chooseDevice: any, refresh: Boolean = false) {
    if (chooseDevice.did) {
      this.$set(this.form, 'subject_id', chooseDevice.did)
    }

    const form = this.form

    const deviceType = deviceField2Type[form.device_type] || ''

    // 当不为isChooseDeviceBtn 设备
    if (refresh && !this.isChooseDeviceBtn)  {
      this.$set(this.form, 'resource_id' , '')

      return Promise.resolve({})
    }

    return new Promise((resolve, reject) => {
      switch (deviceType) {
        case 'lvmi':
          this.merchantApi.resource(
            {
              aqara_id: this.$route.query.aqara_id,
              did: [chooseDevice.did],
            },
            (res: any) => {
              this.rList = res.data;
                resolve(res)
            },
              (err: any) => {
                this.rList = []
                reject(err)
              }
          );
          break;
        case 'zhikong':
          if (this.form.subject_id) {
            this.merchantApi.zkbResource(
              {
                zkb_user_id: this.$route.query.zkb_user_id,
                product_id: chooseDevice.did,
              },
              (res: any) => {
                const {
                  list = []
                } = res.data || {}
                this.rList = list.map((item: any) => {
                  const {
                    // name,
                    index
                  } = item || {}
                  const newItem = {
                    ...item,
                    resourceId: index
                  }

                  return newItem
                });

                resolve(res)
              },
              (err: any) => {
                console.log(err, 'err')
                this.rList = []
                reject(err)
              }
            );
          }
          
          break;
        default:
          resolve({})

      }
    })
    
    
  }

  private hrList: any[] = [];
  private hresource(): void {
    const deviceType = this.deviceType

    if (deviceType !== 'lvmi') return
    if (this.form.horn_subject_id) {
      this.merchantApi.resource(
        {
          aqara_id: this.$route.query.aqara_id,
          did: [this.form.horn_subject_id],
        },
        (res: any) => {
          this.hrList = res.data;
        }
      );
    }
  }

  // 通电时长
  private maxHornTime: number = maxHornTime;
  private handleLimitHornTime(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(maxHornTime, Number(new_val)).toString();

        this.$set(this.form, "horn_time", limit_val);
      },
      (new_val: string) => {
        this.$set(this.form, "horn_time", new_val);
      }
    );
  }

  private lockList: any = [];
  private getLockInfoList() {
    return new Promise((resolve, reject) => {
      this.merchantApi.ttlockList(
        {
          shop_id: this.$route.query.id,
        },
        (res: any) => {
          this.lockList = res.data.list;

          resolve(res.data.list)
        },
        (err: any) => {
          reject(err)
        }
      );
    })
  }

  private getTable(): void {
    this.merchantApi.roomTable(
      {
        shop_id: this.$route.query.id,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  private deleteTable(id: number): void {
    this.$confirm("确认删除该包厢？", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.merchantApi.deleteRoomTable(
        {
          id: id,
        },
        () => {
          this.$message.success("删除成功");
          this.getTable();
        }
      );
    });
  }

  private type: number = 0;
  private async addTable(type: any) {
    // this.hideUpload = false;
    this.form = this.deepLoopUtil.deepCopy(defaultForm);
    this.cover = [];
    this.curDeviceGate = {}
    const list: any = await this.getLockInfoList();

    if (type == 1) {
      this.deviceType = 'lvmi'

      await this.device();

      this.type = 1;
      this.show = true;
      if(this.poolTableCategoryList.length){
        this.form.pool_table_category_id = this.poolTableCategoryList[0].id
      }
      this.form.is_status = 1
    } else {
      const {
        lock_id,
        horn_type
        // lock_data
      } = type || {}

      this.deviceType = deviceField2Type[horn_type]

      this.form.horn_type = horn_type

      await this.device();

      const {
        device_type,
        resource_id
      } = type || {}

      const deviceTopType = deviceField2Type[device_type] || ''

      console.log(type, 'type')
      this.form = this.deepLoopUtil.deepCopy(type, false);

      if (deviceTopType == 'zhikong') {
        Object.assign(this.form, {
          resource_id: Number(resource_id)
        })
      }

      

      await this.commonDeviceList()

      if (lock_id && Array.isArray(list) && list.length) {
        const chooseLockItem: any = list.find((item) => item.lockId == lock_id)

        console.log(chooseLockItem, 'chooseLockItem')

        if (chooseLockItem)
        this.$set(this.form, 'lock', chooseLockItem)
      }


      console.log(this.form, 'this.form')

      this.type = 0;
      this.cover = [...(type.cover_urls || [])];
      const deviceType = this.deviceType

      const commonDList = this.commonDList

      const curDeviceGate = commonDList.find((deviceItem: any) => {
        return deviceItem.did == type.subject_id
      }) || {
        __empty: true
      }

      if (curDeviceGate.__empty) {
        delete curDeviceGate.__empty
        Object.assign(curDeviceGate, {
          deviceName: type.subject_id,
          did: type.subject_id
        })

        this.commonDList.push(curDeviceGate)
      }

      this.curDeviceGate = curDeviceGate

      this.resource(curDeviceGate);

      if (deviceType == 'lvmi') {
        this.hresource();
      }
     
      // this.hideUpload = true;
      this.show = true;
    }
  }
  private poolTableCategoryList: any = [];
  private poolTableCategory(): void {
    this.merchantApi.poolTableCategory(
      {
        page: 1,
        limit: 200,
      },
      (res: any) => {
        this.poolTableCategoryList = res.data.list;
      }
    );
  }
  private getOss(file: File, cb: Function) {
    console.log(file);
    // if (file.type == "image/jpeg" || file.type == "image/png") {
    if (file.type.startsWith("image/")) {
      this.commonApi.getOss(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    } else {
      this.commonApi.getOssvideo(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    }
  }
  private fileChange(file: any, fileList: any[]): void {
    // this.hideUpload = fileList.length >= 1;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      const raw = res.data.data.item;
      let obj: any = {
        id: raw.id,
        url: raw.url,
      };
      this.cover.push(obj);
    });
  }
  private handleRemove(file: any): void {
    // this.hideUpload = false;
    for (let i in this.cover) {
      if (this.cover[i].id == file.id) {
        this.cover.splice(i, 1);
      }
    }
  }

  private handleChangeHornType(val: number) {
    this.deviceType = deviceField2Type[val]

    console.log(this.form, 'this.form');

    Object.assign(this.form, {
      horn_subject_id: '',
    })
    
    if (val !== 1) {
      Object.assign(this.form, {
        horn_resource_id: '',
      })
    }

    this.device()
  }

  private changeChessRoomLoading: boolean = false

  private submit(): void {
    const extraParams: any = {

    }

    // if (this.cover.length == 0) {
    //   Util.showToast("请上传包厢封面");
    //   return;
    // }

    const deviceType = this.deviceType

    const { horn_subject_id, horn_resource_id, horn_time, lock } = this.form || {};
    this.form.cover_ids = this.cover
      .map(({ id }: { id: number }) => id)
      .join(",");

    if (deviceType == 'lvmi') {
      if (horn_subject_id || horn_resource_id) {
        if (horn_subject_id && !horn_resource_id) {
          this.$message.error("请选择云喇叭按钮ID");
          return;
        }
        const horn_time_num = Number(horn_time);
        if (!horn_time || isNaN(horn_time_num) || horn_time_num <= 0) {
          this.$message.error("通电时长最少1秒");
          return;
        }
      }
    }

    extraParams.horn_type = deviceType2Fields[deviceType]


    if (lock && lock.lockId) {
      extraParams.lock_data = lock.lockData
      extraParams.lock_id = lock.lockId
    }

    this.changeChessRoomLoading = true

    if (this.type == 1) {
      this.form.shop_id = this.$route.query.id;
      this.merchantApi.addRoomTable({
        ...this.form,
        ...extraParams
      }, () => {
        this.show = false;
        this.changeChessRoomLoading = false
        this.form = new Util().clearObject(this.form);
        Util.showToast("新增成功");
        this.getTable();
      }, () => {
        this.changeChessRoomLoading = false
      });
    } else {
      this.merchantApi.saveRoomTable({
        ...this.form,
        ...extraParams
      }, () => {
        this.show = false;
        this.changeChessRoomLoading = false
        this.form = new Util().clearObject(this.form);
        Util.showToast("修改成功");
        this.getTable();
      }, () => {
        this.changeChessRoomLoading = false
      });
    }
  }
  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private handleClose(done: any): void {
    this.$confirm("确认关闭？")
      .then((_) => {
        done();
      })
      .catch((_) => {});
  }

  // private preview(file: any) {
  //   this.dialogVisible = true;
  //   this.dialogImageUrl = file.url;
  // }
}
